<script setup>
/* eslint-disable no-unused-vars */
import { PricingCard, Switch } from "@/components";
import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import { getOffers, purchaseOffer } from "@/http/offer";
import { useStore } from "vuex";
import { ElMessageBox, ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import {
  openKkiapayWidget,
  addKkiapayListener,
  removeKkiapayListener,
} from "kkiapay";

const router = useRouter();
const store = useStore();
const user = computed(() => store.getters["user/profile"]);
const data = ref();
const loading = ref(false);

const paybtn = ref();
const subscription_type = ref("month");
const chosen_offer = ref();

onMounted(() => {
  fetchOffers();
  addKkiapayListener("success", onComplete);
});

onBeforeUnmount(() => {
  removeKkiapayListener("success", onComplete);
});

const fetchOffers = async () => {
  loading.value = true;
  await getOffers()
    .then((response) => {
      loading.value = false;
      data.value = response.data;
    })
    .catch(() => {
      loading.value = false;
      ElMessage("Erreur lors du chargement de l'offre");
    });
};

const initPayment = (offer) => {
  chosen_offer.value = offer;
  if (user.value) {
    let prix = null;
    if (subscription_type.value === "month") {
      prix = offer.price;
    } else {
      prix = offer.price * 12 * (1 - offer.annual_reduction / 100);
    }
    /*
      let customer = null
    customer = {
       email: user.value?.email,
      lastname: user.value?.name,
      firstname: user.value?.name,
      phone: user?.value?.phone,
    };
    // eslint-disable-next-line no-undef
    FedaPay.init("#pay-btn", {
      public_key: "pk_live_VuwhbgMh1FS8r-bKRFscPPbT",
      transaction: {
        amount: prix,
        description: offer?.name + " - " + offer?.description,
      },
      customer,
      onComplete(resp) {
        const FedaPay = window["FedaPay"];
        if (resp.reason === FedaPay.DIALOG_DISMISSED) {
          ElMessageBox.alert("La transaction n'a pas abouti", "Alerte", {
            confirmButtonText: "D'accord",
          });
        } else {
          purchase(offer?.id, String(resp.transaction.id));
        }
      },
    });
     */
    openKkiapayWidget({
      amount: prix,
      api_key: "d96163be613b25aa2e82b3e7163116af14cf2f04",
      sandbox: false,
    });
    paybtn.value.click();
  } else {
    ElMessageBox.alert(
      "Vous devez être connecté pour souscrire à une offre",
      "Alerte",
      {
        confirmButtonText: "Se connecter",
      }
    ).then(() => {
      router.push("/connexion?redirect=/tarifs");
    });
  }
};

const onComplete = (resp) => {
  purchase(String(resp.transactionId));
};

const purchase = async (transaction_id = null) => {
  try {
    await purchaseOffer(chosen_offer.value?.id, {
      transaction_id: transaction_id,
      subscription_type: subscription_type.value,
    }).then(async () => {
      location.reload();
    });
  } catch (e) {
    ElMessageBox.alert(
      e?.response?.data?.fr ||
        e?.response?.data?.message ||
        "Une erreur s'est produite",
      "Erreur de vérification",
      {
        confirmButtonText: "D'accord",
      }
    );
  }
};
</script>
<template>
  <div id="tarifs">
    <h3 class="sub">
      <span v-if="user?.active_user_offers[0]">
        Votre abonnement actuel est
        {{ user?.active_user_offers[0]?.offer?.name }}. Il vous reste {{user?.offer_remaining_days}} jours.
      </span>
      <span v-else>
        Vous n’avez aucun abonnement. Veuillez mettre à niveau.
      </span>
    </h3>
    <el-skeleton :loading="loading" animated :throttle="500" class="w-100">
      <template #template>
        <div class="row row-cols-1 row-cols-md-3">
          <div v-for="item in 3" :key="item" class="col">
            <el-skeleton-item variant="h1" class="w-100 mt-5" />
            <el-skeleton-item variant="p" class="w-100" />
            <el-skeleton-item
              v-for="item in 8"
              :key="item"
              variant="text"
              class="w-100"
            />
            <el-skeleton-item variant="button" />
          </div>
        </div>
      </template>
      <template #default>
        <Switch v-model:value="subscription_type" />
        <div class="center">
          <div class="tarifs__grids">
            <PricingCard
              v-for="(item, index) in data"
              :key="index"
              :contents="item"
              :currentIndex="subscription_type"
              @checkout="initPayment"
            />
          </div>
        </div>
      </template>
    </el-skeleton>
  </div>
  <div id="pay-btn" ref="paybtn"></div>
</template>

<style scoped>
#tarifs {
  margin: 60px 30px 30px 30px;
}

.headTitle {
  text-align: center;
  color: #2696be;
  font-weight: 700;
  margin-bottom: 10px;
}

.sub {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.content1 {
  width: 79%;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
}

.tarifs__grids {
  display: flex;
  margin: 60px 0 0 0;
  flex-wrap: wrap;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
