import request from './request'

export function getOffers() {
  return request({
    url: 'offers',
    method: 'get'
  })
}

export function getOffer(id) {
  return request({
    url: 'offers/' + id,
    method: 'get'
  })
}

export function getOffersPurchased() {
  return request({
    url: 'offers/purchased',
    method: 'get'
  })
}

export function purchaseOffer(id, data) {
  return request({
    url: 'offers/' + id + '/purchase',
    method: 'post',
    data
  })
}
